import { Next } from '@styled-icons/foundation/Next';
import { Play } from '@styled-icons/fa-solid/Play';
import { HorseHead } from '@styled-icons/fa-solid/HorseHead';
import { SportBasketball } from '@styled-icons/fluentui-system-filled/SportBasketball';
import { MonitorOutline } from '@styled-icons/evaicons-outline/MonitorOutline';
import { SearchAlt } from '@styled-icons/boxicons-regular/SearchAlt';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import SGMLogo from '@/assets/icons/sgm-icon.svg';
import SRMLogo from '@/assets/icons/srm-icon.svg';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { isSweeps } from '@/configs/brands.config';
import { isEmbeddedApp } from '@/lib/embed';

const tabs = isEmbeddedApp()
  ? [
      {
        to: '/trade-manager/in-play',
        text: 'In Play',
        icon: Play,
        dataCy: 'tradeManagerInPlayLink',
        isHidden: !FEATURE_FLAGS.IS_IN_PLAY_ENABLED,
      },
      {
        to: '/trade-manager/by-sport',
        text: 'Sport',
        icon: SportBasketball,
        dataCy: 'tradeManagerBySportLink',
      },
      {
        to: '/trade-manager/find-an-event',
        text: 'Find an event',
        icon: SearchAlt,
        dataCy: 'tradeManagerBySportLink',
      },
    ]
  : [
      {
        to: '/trade-manager',
        text: 'Next to jump',
        icon: Next,
        dataCy: 'tradeManagerNextToJumpLink',
      },
      {
        to: '/trade-manager/in-play',
        text: 'In Play',
        icon: Play,
        dataCy: 'tradeManagerInPlayLink',
        isHidden: !FEATURE_FLAGS.IS_IN_PLAY_ENABLED,
      },
      {
        to: '/trade-manager/by-racing',
        text: 'Racing',
        icon: HorseHead,
        dataCy: 'tradeManagerRacingLink',
        isHidden: isSweeps,
      },
      {
        to: '/trade-manager/by-sport',
        text: 'Sport',
        icon: SportBasketball,
        dataCy: 'tradeManagerBySportLink',
      },

      {
        to: '/trade-manager/find-an-event',
        text: 'Find an event',
        icon: SearchAlt,
        dataCy: 'tradeManagerBySportLink',
      },

      {
        to: '/trade-manager/multi-monitor',
        text: 'Multi Monitor',
        icon: MonitorOutline,
        dataCy: 'tradeManagerBySportLink',
      },

      {
        to: '/trade-manager/sgm',
        text: 'SGM',
        icon: SGMLogo,
        dataCy: 'tradeManagerBySportLink',
        isHidden: !FEATURE_FLAGS.IS_SGM_ENABLED,
      },

      {
        to: '/trade-manager/srm',
        text: 'SRM',
        icon: SRMLogo,
        dataCy: 'tradeManagerBySportLink',
        isHidden: !FEATURE_FLAGS.IS_SRM_ENABLED || isSweeps,
      },

      {
        to: '/trade-manager/custom-markets',
        text: 'Custom Markets',
        icon: Customize,
        dataCy: 'tradeManagerBySportLink',
        isHidden:
          !isWincore &&
          !FEATURE_FLAGS.IS_CREATING_CUSTOM_MARKETS_IN_CORE_ENABLED,
      },
    ];

export default tabs;
