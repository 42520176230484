import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';

/**
 * MSW allows us to intercept requests in the browser
 */
const { NODE_ENV, REACT_APP_MOCKING } = window.BETCLOUD_ENV;
if (NODE_ENV === 'development' && REACT_APP_MOCKING === 'true') {
  const { worker } = require('./__mocks__/browser');
  worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
