import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Login, Punters } from '../features';
import { PromoManagerWrapper } from '@/wrappers/PromoManager/PromoManager';
import { BulkBonusAwards } from '@/views/PromoManager/components/BulkBonusAwards';
import DepositMatch from '@/features/promoManager/pages/PromoManager/tabs/DepositMatch';
import BetsApprovalsList from '../features/betApprovals/pages/BetApprovals/tabs/Approvals';
import Upcoming from '../features/bets/pages/PlacedBets/tabs/Upcoming/Upcoming';
import Withdrawals from '../features/banking/pages/Bank/tabs/Withdrawals';
import NextToJumpTab from '../features/tradeManager/pages/TradeManager/tabs/NextToJump';
import Bets from '../features/punters/pages/Bets/Bets';
import SettingsLimits from '../features/settings/pages/Settings/tabs/Limits';
import PrivateLayout from '../layouts/PrivateLayout/PrivateLayout';
import PublicLayout from '../layouts/PublicLayout/PublicLayout';
import TradeManagerWrapper from '../wrappers/TradeManager/TradeManager';
import { Card } from '../wrappers/Primary/Card';
import BankWrapper from '../wrappers/Bank/Bank';
import BetApprovalsWrapper from '../wrappers/BetApprovals/BetApprovals';
import PlacedBetsWrapper from '../wrappers/PlacedBets/PlacedBets';
import PunterWrapper from '../wrappers/Punter/Punter';
import SettingsWrapper from '../wrappers/Settings/Settings';
import SettingsOfferingsWrapper from '../wrappers/SettingsOfferings/SettingsOfferings';
import PrivateRoute from '@/components/Routes/PrivateRoute';
import RestrictedRoute from '@/components/Routes/RestrictedRoute';
import PunterHeader from '../wrappers/PunterHeader/PunterHeader';
import {
  AdminAccounts,
  BetfairIntegration,
  ContactDetails,
  FeaturedEvents,
  OfferingsTab,
  SettingsActivtyLogs,
  SystemSetup,
  YourProfile,
} from './settings/settings';
import {
  ActivityLog,
  Incentives,
  Limits,
  Notes,
  Offerings,
  Payments,
  Profile,
  PunterDashboard,
  PunterDeposits,
  Transactions,
} from './punters/punters';
import { BankAnalysisDashboard, Deposits } from './bank/bank';
import {
  ByRacingTab,
  BySportTab,
  FindAnEventTab,
  HighestExposureTab,
  ManageGlobalMarginsTab,
  ManageMyCustomMarketsTab,
  MatchDetails,
  MultiMonitor,
  RaceDetails,
  SameGameMultiTab,
  SameRaceMultiTab,
} from './tradeManager/tradeManager';
import { BetsDashboard, Flagged, Resulted } from './placedBets/placedBets';
import { BetsApprovalHistoryList } from './betApprovals/betApprovals';
import { ForgotPassword } from './onboarding/onboarding';
import { CustomMarkets } from '@/features/tradeManager/pages/TradeManager/tabs/CustomMarkets/CustomMarkets';
import RegulatorWrapper from '@/wrappers/Regulator/Regulator';
import FinancialTransactionHistory from '@/views/Regulator/tabs/FinancialTransactionHistory/FinancialTransactionHistory';
import BettingHistory from '@/views/Regulator/tabs/BettingHistory/BettingHistory';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import Banners from '@/features/promoManager/pages/PromoManager/tabs/Banners/Banners';
import Banner from '@/features/promoManager/pages/PromoManager/tabs/Banners/views/Banner/Banner';
import Scoreboard from '@/features/scoreboard/Scoreboard';
import CMS from '@/features/settings/pages/Settings/tabs/CMS/Cms';
import Affiliates from '@/features/promoManager/pages/PromoManager/tabs/Affiliates/views/Affiliates';
import Promotion from '@/features/promoManager/pages/PromoManager/tabs/Promotions/views/Promotion/Promotion';
import Promotions from '@/features/promoManager/pages/PromoManager/tabs/Promotions/Promotions';
import { OddsBoostContainer } from '@/features/promoManager/pages/PromoManager/tabs/OddsBoost/OddsBoost';
import { Dashboard } from './dashboard/Dashboard';
import MoneyBackContainer from '@/features/promoManager/pages/PromoManager/tabs/MoneyBack/MoneyBack';
import MoneyBackTableContainer from '@/features/promoManager/pages/PromoManager/tabs/MoneyBack/views/List/List';
import TreasureHuntTableContainer from '@/features/promoManager/pages/PromoManager/tabs/TreasureHunt/views/List/List';
import TreasureHuntContainer from '@/features/promoManager/pages/PromoManager/tabs/TreasureHunt/TreasureHunt';
import { InPlay } from '@/features/tradeManager/pages/TradeManager/tabs/InPlay/InPlay';

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to={
              FEATURE_FLAGS.IS_REGELATOR
                ? '/regulator/betting-history'
                : '/trade-manager'
            }
            replace
          />
        }
      />

      <Route element={<PrivateLayout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Route>

      <Route element={<PublicLayout />}>
        <Route element={<RestrictedRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
      </Route>

      <Route element={<PrivateLayout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/bet-approvals">
            <Route element={<Card noBottomPad />}>
              <Route element={<BetApprovalsWrapper />}>
                <Route index element={<Navigate to="approvals" />} />
                <Route path="approvals" element={<BetsApprovalsList />} />
                <Route path="history" element={<BetsApprovalHistoryList />} />
              </Route>
            </Route>
          </Route>

          <Route path="/placed-bets">
            <Route element={<Card noBottomPad />}>
              <Route element={<PlacedBetsWrapper />}>
                <Route index element={<Navigate to="upcoming" />} />
                <Route path="upcoming" element={<Upcoming />} />
                <Route path="resulted" element={<Resulted />} />
                <Route path="bets-dashboard" element={<BetsDashboard />} />
                <Route path="flagged" element={<Flagged />} />
              </Route>
            </Route>
          </Route>

          <Route path="/bank">
            <Route element={<Card noBottomPad />}>
              <Route element={<BankWrapper />}>
                <Route
                  index
                  element={
                    <Navigate
                      to="withdrawals?withdrawalFilter=pending"
                      replace
                    />
                  }
                />
                <Route path="withdrawals" element={<Withdrawals />} />
                <Route path="deposits" element={<Deposits />} />
                <Route
                  path="analysis-dashboard"
                  element={<BankAnalysisDashboard />}
                />
              </Route>
            </Route>
          </Route>

          <Route path="/trade-manager">
            <Route element={<Card noBottomPad />}>
              <Route element={<TradeManagerWrapper />}>
                <Route index element={<NextToJumpTab />} />
                {FEATURE_FLAGS.IS_IN_PLAY_ENABLED && (
                  <Route path="in-play" element={<InPlay />} />
                )}
                <Route path="by-racing" element={<ByRacingTab />} />
                <Route path="by-sport" element={<BySportTab />} />

                <Route
                  path="highest-exposure"
                  element={<HighestExposureTab />}
                />
                <Route path="find-an-event" element={<FindAnEventTab />} />
                {FEATURE_FLAGS.IS_SGM_ENABLED && (
                  <Route path="sgm" element={<SameGameMultiTab />} />
                )}
                {FEATURE_FLAGS.IS_SRM_ENABLED && (
                  <Route path="srm" element={<SameRaceMultiTab />} />
                )}
                <Route
                  path="manage-my-custom-markets"
                  element={<ManageMyCustomMarketsTab />}
                />
                <Route
                  path="manage-global-margins"
                  element={<ManageGlobalMarginsTab />}
                />
                <Route path="multi-monitor" element={<MultiMonitor />} />

                <Route path="custom-markets" element={<CustomMarkets />} />
              </Route>
            </Route>
            <Route path="race-details" element={<RaceDetails />} />
            <Route path="match-details" element={<MatchDetails />} />
          </Route>

          <Route path="/punter">
            <Route element={<PunterHeader />}>
              <Route element={<Card noBottomPad />}>
                <Route element={<PunterWrapper />}>
                  <Route index element={<Navigate to="/" replace />} />

                  <Route path=":punterId">
                    <Route index element={<Navigate to="bets" replace />} />
                    <Route path="bets" element={<Bets />} />
                    <Route path="payments" element={<Payments />} />
                    <Route path="deposit" element={<PunterDeposits />} />
                    <Route path="incentives" element={<Incentives />} />
                    <Route path="limits" element={<Limits />} />
                    <Route path="notes" element={<Notes />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="chat" element={<PunterDashboard />} />
                    <Route path="activity-log" element={<ActivityLog />} />
                    <Route path="transactions" element={<Transactions />} />
                    <Route path="offerings" element={<Offerings />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="/settings">
            <Route element={<Card noBottomPad />}>
              <Route element={<SettingsWrapper />}>
                <Route index element={<Navigate to="limits" replace />} />

                <Route path="limits" element={<SettingsLimits />} />

                <Route
                  path="betfair-integration"
                  element={<BetfairIntegration />}
                />
                <Route path="system-setup" element={<SystemSetup />} />
                <Route path="admin-accounts" element={<AdminAccounts />} />
                <Route path="activity-log" element={<SettingsActivtyLogs />} />
                <Route path="your-profile" element={<YourProfile />} />
                <Route path="contact-us" element={<ContactDetails />} />
                <Route path="cms" element={<CMS />} />

                <Route element={<SettingsOfferingsWrapper />}>
                  <Route path="offerings">
                    <Route
                      index
                      element={<Navigate to="offerings" replace />}
                    />
                    <Route path="offerings" element={<OfferingsTab />} />
                    <Route
                      path="featured-events"
                      element={<FeaturedEvents />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="/regulator">
            <Route element={<Card noBottomPad />}>
              <Route element={<RegulatorWrapper />}>
                <Route
                  index
                  element={<Navigate to="betting-history" replace />}
                />

                <Route path="betting-history" element={<BettingHistory />} />

                <Route
                  path="financial-transaction-history"
                  element={<FinancialTransactionHistory />}
                />
              </Route>
            </Route>
          </Route>

          <Route path="/promo-manager">
            <Route element={<PromoManagerWrapper />}>
              <Route index element={<BulkBonusAwards />} />
              <Route path="deposit-match" element={<DepositMatch />} />
              <Route path="banners" element={<Banners />} />
              <Route path="promotions" element={<Promotions />} />
              <Route path="affiliates" element={<Affiliates />} />
              <Route path="odds-boost" element={<OddsBoostContainer />} />
              <Route path="money-back" element={<MoneyBackTableContainer />} />
              <Route
                path="treasure-hunt"
                element={<TreasureHuntTableContainer />}
              />
            </Route>

            <Route path="promotion/:promotionId" element={<Promotion />} />
            <Route path="promotion/add-new" element={<Promotion />} />
            <Route path="banner/:bannerId" element={<Banner />} />
            <Route path="banner/add-banner" element={<Banner />} />
            {FEATURE_FLAGS.IS_MONEY_BACK_ENABLED && (
              <>
                <Route
                  path="money-back/promotion"
                  element={<MoneyBackContainer />}
                />
                <Route
                  path="money-back/add-promotion"
                  element={<MoneyBackContainer />}
                />
              </>
            )}
            {FEATURE_FLAGS.IS_TREASURE_HUNT_ENABLED && (
              <>
                <Route
                  path="treasure-hunt/promotion"
                  element={<TreasureHuntContainer />}
                />
                <Route
                  path="treasure-hunt/add-promotion"
                  element={<TreasureHuntContainer />}
                />
              </>
            )}
          </Route>

          <Route path="/punters" element={<Punters />} />
          <Route path="/scoreboard" element={<Scoreboard />} />

          <Route path="/*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Routes>
  );
}
