import React, { useEffect } from 'react';

import { ENV } from '@/lib/Constants';

export function ExchangeToken() {
  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('access_token');
    if (token) {
      fetch(
        `${ENV.REACT_APP_API_URL}/admin/auth/exchange_token?access_token=${token}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      ).then((res) => {
        if (res.ok) {
          window.location.href = url.searchParams.get('redirect_url') ?? '/';
        } else {
          throw new Error('Invalid token');
        }
      });
    }
  }, []);

  return <>Loading...</>;
}
